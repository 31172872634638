<template>
    <v-flex md4 mx-auto>
        <v-card class="pa-5 w100 text-center">
            <h1>
                404
                <br>
                Page Not Found
            </h1>
        </v-card>
    </v-flex>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style scoped>

</style>